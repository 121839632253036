import React, { Component } from 'react';
import { Form, Button, Message, Input, Dimmer, Loader } from 'semantic-ui-react'
import './Calendar.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { Calendar, NextIcon } from 'react-calendar';
import ReactDOM from 'react-dom'
import { authAxios } from '../utils';
import { UpdateUserURL, GetEmployeeInformationURL, GetInvalidWeekURL, InsertPatchEmployeeAsyncURL } from '../constants';
import { authContext } from '../adalConfig';
import alert from '../img/Alert.PNG';
import forwardarrow from '../img/forwardarrow.PNG';
import backarrow from '../img/backarrow.PNG';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter';
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter';



let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
class CalendarComp extends Component {
    constructor(props) {
        super();
        window.calcomp = this;
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
        this.handleCalClickBack = this.handleCalClickBack.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleDateSelected = this.handleDateSelected.bind(this); 
        this.state = {
            selectedDay: null,
            invalidDates: [],
            disabledDates: null,
            userRole: null,
            loading: false,
            error: false,
            key: ''
        };

    }

   //handleDayClick(day, { selected }) {
   //     this.setState({
   //         selectedDay: selected ? undefined : day,
   //     });
   // }

    handleDayClick(day, modifiers = {}) {

        if (modifiers.disabled) {
            return;
        }
        this.setState({
            selectedDay: modifiers.selected ? undefined : day,
            error: false

        });


    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        let allEnableMondays = [];
        for (let i = 0; i < 8; i++) {
            let monday = (this.DateNow().getDate() + (7 * i));
            allEnableMondays.push(new Date(this.DateNow().setDate(monday)).toLocaleDateString());
        }
        let invalidMondayResponses = []
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }

        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                let upgradeWeekAppointment = !data.upgradeWeek ? null : new Date(data.upgradeWeek)
                this.setState({
                    selectedDay: upgradeWeekAppointment, userRole: data.userRole, key: data.key
                });

                let LOB = data.userRole == 'CORPORATE' ? 'CORP' : data.userRole === 'PROTIVITI' ? 'PRO' : 'FIELD'; 
                authAxios.post(GetInvalidWeekURL, {
                    upgradeWeeks: allEnableMondays,
                    Country: data.country,
                    LOB: LOB
                })
                    .then(response => {
                        invalidMondayResponses = response.data;
                        let disableDates = [];
                        disableDates.push({ daysOfWeek: [0, 2, 3, 4, 5, 6] });
                        disableDates.push({
                            before: this.DateNow()
                        });
                        disableDates.push({
                            after: this.DateFuture()
                        });
                        invalidMondayResponses.map(_ =>
                            disableDates.push(new Date(_))
                        );
                        this.setState({
                            disabledDates: disableDates,
                            loading: false,
                        })
                        if (invalidMondayResponses.indexOf(data.upgradeWeek) !== -1) { // if employee upgradeWeek exists in invalidMondayResponses
                            this.setState({
                                selectedDay: null
                            })
                        }

                    }).catch((error) => {
                        console.log('error ' + error);
                        this.setState({ exceededMaxSlotDate: true, loading: false });
                    })

            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
        
        
    }


    componentDidUpdate(prevProps, prevState) {
        const { selectedDay } = this.state;
        if (selectedDay !== prevState.selectedDay) {
            this.date = this.state.selectedDay;
            this.setState({
                selectedDay
            })
        }
    }

    ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        this.props.requestWeatherForecasts(startDateIndex);

    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    handleCalClickBack() {
        if (this.state.userRole === 'PROTIVITI')
            this.props.history.push('./selection');
        else if (this.state.userRole === 'CORPORATE')
            this.props.history.push('./selectioncorp');
        else if (this.state.userRole === 'FIELD')
            this.props.history.push('./selectionfield');
    }

    state = {
        date: new Date(),
        selectedDay: "",
        exceededMaxSlotDate: false


    }


    handleNextClick() {
        if (this.state.selectedDay) {
            /*authAxios.put(UpdateUserURL, {
                upgradeWeek: this.formatDate(this.state.selectedDay),
                PageNumber: '3'
            })*/
            authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                [
                    {
                        "op": "replace",
                        "path": "/UpgradeWeek",
                        "value": this.formatDate(this.state.selectedDay)
                    }
                ])
                .then(response => {
                    this.setState({ exceededMaxSlotDate: false });
                    this.props.history.push('./software');
                })

                .catch((error) => {
                    console.log('error: ' + error);
                    //authContext.login();
                    //this.handleNextClick();
                });

        }
        else {
            this.setState({
                error: true
            })
           
        }
        return false;
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }

    onChange = async function (date) {
         
        await this.setState({ date: date });
        await this.setState({ selectedDay: this.date });
    }


    DateNow = () => {
        let today = new Date();
        var days = 21;
        let firstMondayOfTheCurrentWeek = today.getDate() - today.getDay() + (today.getDay() == 0 ? -6 : 1) + days;
        return new Date(today.setDate(firstMondayOfTheCurrentWeek));
    }


    DateFuture = () => {
        let today = new Date();
        var days = 70;
        let firstMondayOfTheCurrentWeek = today.getDate() - today.getDay() + (today.getDay() == 0 ? -6 : 1) + days;
        return new Date(today.setDate(firstMondayOfTheCurrentWeek));
    }


    passActiveDate(activeDate) {
        const monthAfterPress = activeDate.getMonth();
        console.log(monthAfterPress);
    }

    formatMonthTitle(d, locale) {
    return `${d.getMonth() + 1}/${d.getFullYear}`
    }

    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHHeader />;
        else if (this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHFooter />;
        else if (this.state.userRole === 'FIELD')
            return <RHFooter />;
    }

    handleDateSelected() {
        if (this.state.selectedDay) {
            return (
                <React.Fragment>
                    <Form.Field>
                        <label className='selecteddate'>
                            Selected Date: {new Date (this.state.selectedDay).toLocaleDateString()}
                        </label>
                    </Form.Field>
                </React.Fragment>
            )
        }
    }

    userErrorMessage = () => {
        return (
            <React.Fragment>
                {this.state.error === true &&
                    <h2 className="errorreviewUIselect" >
                        Error: You must select a date.
                    </h2>

                }

            </React.Fragment>
        )
    }

    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Available Dates...</Loader>
                    </Dimmer>
                </div>
            )

        const { userRole } = this.state;
        return (

            <div className='outer-container'>
                <div className='inner-container'>
                    {this.handleHeaderSelection()}

                    

                    <h1 className="h1Cal"> Upgrade Week </h1>

                    <div className= 'calwrapper'>
                        <div className="leftside">
                            <div className="HeaderStrings">
                                <h4 className='h4cal'>Prior to selecting a week, consult with your engagement manager about any potential impact the upgrade process will have on your work commitments. </h4>

                        
                            </div>

                            <div className="LowerStrings">
                                <h4 className='h4cal'> Please select a Monday from the calendar on the right to choose your Upgrade Week.</h4>
                                <h4 className='h4cal'> Once you receive the new computer, you will have one week to complete the set-up and data migration.</h4>
                            </div>

                            <Form className="DateInput"  >
                                    
                                {this.handleDateSelected()}

                                
                                <Button
                                    type='submit'
                                    color="green"
                                    onClick={this.handleCalClickBack}
                                    className="calbackbutton">
                                    <i class="left arrow icon"></i> Back
                                </Button>
                                <Button
                                    type='submit'
                                    required
                                    color="green"
                                    onClick={this.handleNextClick}
                                    className="nextbtton"
                                >
                                    Next
                                    <i class="right arrow icon">
                                    </i>
                                </Button>         

                                <Button className="calhelpbutton" onClick={this.handleGoToHelp}>
                                    Help
                                </Button>

                                {this.userErrorMessage()}
                            </Form>

                    
                   
                        </div>

                        <div className="rightside">
                   
                            <DayPicker
                                onDayClick={this.state.date}
                                //selectedDays={this.handleSelect} //when day is clicked
                                // selectedDays={this.state.date}
                                //onDayChange={(d) => { this.onChange(d) }}
                                numberOfMonths={2}
                                selectedDays={new Date(this.state.selectedDay)}
                                onDayClick={this.handleDayClick}
                                formatMonthTitle
                                disabledDays={this.state.disabledDates}
                                month={this.state.month}
                                onInteraction={this.handleDaySelect}
                                onMonthChange={this.handleMonthChange}
                                modifiers={
                                    {
                                        monday: { daysOfWeek: [1] }
                                    }
                                }
                                value={this.date}
                                onChange={(d) => { this.onChange(d) }}
                                className={userRole === 'CORPORATE' ? 'CORP' : userRole === 'FIELD' ? 'FIELD' : 'PRO'}

                            
                                    
                            />
  
                            <div>
                                <p className='RedText'>
                                    If there are no available Mondays in the current months, please use the <br />
                                    arrow buttons next to the calendar to check for availability in the following months.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                {this.handleFooterSelection()}
            </div>
               
        );
    }
}
export default connect()(CalendarComp);
